@import "variables";
@import "framework/framework";
@import "../../node_modules/magnific-popup/src/css/main";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";

html {
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	line-height: 1;
	overflow-x: hidden;
}

body {
	background: $color-gray-2;
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	position: relative;

	> img {
		width: 100%;

		&.desktop-img {
			@include tablet-down {
				display: none;
			}
		}

		&.mobile-img {
			display: none;

			@include tablet-down {
				display: block;
			}
		}
	}

	> .container {
		left: 0;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		@include desktop-sm-down {
			position: static;
			transform: none;
		}

		> .content {
			max-width: 100%;
			text-align: center;
			width: rem(700);

			@include desktop-sm-down {
				margin: 0 auto;
				padding: 0 0 rem(60) 0;
			}

			> h1 {
				margin: 0;
			}

			> nav {
				margin: rem(-50) 0 0 0;

				@include phone-down {
					margin-top: 0;
				}

				> ul {
					list-style: none;
					margin: 0;
					padding: 0;

					> li {
						display: inline-block;
						font-family: 'Tungsten';
						font-size: rem(50);
						font-weight: 600;
						letter-spacing: letter-spacing(50);
						line-height: 1;
						text-indent: text-indent(50);
						text-transform: uppercase;
						vertical-align: middle;

						@include phone-down {
							font-size: rem(30);
						}

						@include phone-sm-down {
							font-size: rem(20);
						}

						+ li {
							margin-left: rem(50);

							@include phone-down {
								margin-left: rem(30);
							}

							@include phone-sm-down {
								margin-left: rem(15);
							}
						}

						> a {
							color: $color-white;
							text-shadow: 0px 0px 19.53px rgba(34, 19, 16, 0.71);

							@include hover-focus {
								color: $color-black;
							}
						}
					}

					&.socials {
						margin: rem(40) 0;

						> li {
							font-size: rem(30);

							+ li {
								margin-left: rem(20);
							}
						}
					}
				}

				> a {
					background: rgba(0, 0, 0, 0.59);
					border: 2px solid $color-white;
					display: inline-block;
					font-family: 'Tungsten';
					font-size: rem(36);
					font-weight: 600;
					letter-spacing: letter-spacing(200);
					line-height: 1;
					opacity: 1;
					padding: rem(10) rem(20);
					text-align: center;
					text-indent: text-indent(200);
					text-transform: uppercase;
					vertical-align: middle;

					@include hover-focus {
						opacity: 0.8;
					}

					@include phone-sm-down {
						font-size: rem(25);
					}
				}
			}
		}
	}
}

section {
	.section-title {
		font-family: 'Tungsten';
		font-size: rem(48);
		font-weight: 600;
		letter-spacing: letter-spacing(175);
		line-height: 1;
		margin: 0 0 rem(120) 0;
		text-align: center;
		text-indent: text-indent(175);
		text-transform: uppercase;

		@include tablet-down {
			font-size: rem(30) !important;
			margin-bottom: rem(30);
		}
	}

	&#music {
		background: $color-tan url('../img/paper-bg-1.png') no-repeat center top;
		background-size: cover;
		padding: rem(90) 0;

		.owl-carousel {
			padding: 0 rem(50);

			@include tablet-down {
				padding: 0 rem(30);
			}

			.owl-nav {
				> button {
					font-size: rem(35);
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					&.owl-prev {
						left: 0;
					}

					&.owl-next {
						right: 0;
					}

					@include tablet-down {
						font-size: rem(20);
					}
				}
			}
		}

		.section-content {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			@include desktop-sm-down {
				display: block;
				text-align: center;
			}

			> div {
				flex: 0 0 50%;

				&:first-child {
					> img {
						/*box-shadow: 8px 12px 51px 0px rgba(91, 44, 4, 0.49);*/
						margin: 0 auto;
					}
				}

				&:last-child {
					padding-left: rem(30);

					@include desktop-sm-down {
						margin-top: rem(50);
						padding-left: 0;
					}

					.embed {
						align-items: center;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: space-between;

						> iframe {
							flex: 0 1 auto;
						}

						> img {
							flex: 0 1 auto;
							margin-left: rem(15);
							width: auto;
						}

						@include desktop-sm-down {
							justify-content: space-around;
						}

						@include phone-down {
							display: block;

							> iframe, > img {
								margin: 0 auto;
							}

							> img {
								margin-left: 0;
								margin-top: rem(30);
							}
						}
					}

					.buy-links {
						list-style: none;
						margin: rem(50) 0 0 rem(-5);
						padding: 0;

						@include desktop-sm-down {
							margin-left: 0;
						}

						> li {
							display: inline-block;
							padding: rem(5);
							vertical-align: middle;

							> a {
								display: inline-block;
								opacity: 1;
								vertical-align: middle;

								@include hover-focus {
									opacity: 0.8;
								}
							}
						}
					}
				}
			}
		}
	}

	&#bio {
		background: #1b1718;
		position: relative;

		@include tablet-down {
			padding: 0 !important;
		}

		> img {
			bottom: 0;
			height: 100%;
			left: 0;
			object-fit: cover;
			object-position: center top;
			position: absolute;
			top: 0;
			z-index: 1;

			@media screen and (max-width: 1399px) {
				left: rem(-100);
			}

			@include desktop-sm-down {
				height: auto;
				position: static;
			}
		}

		.section-content {
			margin: 0 0 0 auto;
			max-width: 100%;
			padding: rem(150) 0;
			position: relative;
			width: rem(700);
			z-index: 2;

			@include desktop-sm-down {
				margin: 0 auto;
			}

			@include tablet-down {
				padding: rem(60) 0;
			}

			.section-title {
				margin-bottom: rem(50);
				text-align: left;
			}

			.short-bio {
				font-size: rem(17);
				line-height: line-height(24, 17);

				p {
					margin: 0;

					+ p {
						margin-top: rem(30);
					}
				}

				a {
					border-bottom: rem(3) solid $color-white;
					display: inline-block;
					font-family: 'Tungsten';
					font-size: rem(28);
					font-weight: 600;
					letter-spacing: letter-spacing(250);
					line-height: 1;
					margin-top: rem(50);
					opacity: 1;
					padding-bottom: rem(8);
					text-indent: text-indent(250);
					text-transform: uppercase;
					vertical-align: middle;

					@include hover-focus {
						opacity: 0.8;
					}
				}
			}
		}
	}

	&#tour {
		background: $color-tan;
		color: $color-black;
		position: relative;

		@include tablet-down {
			padding: 0 !important;
		}

		.section-content {
			max-width: 100%;
			padding: rem(90) 0;
			width: rem(700);

			@include tablet-down {
				margin: 0 auto;
				padding: rem(60) 0;
			}

			.section-title {
				margin-bottom: rem(60);
				text-align: left;

				@include tablet-down {
					text-align: center;
				}
			}

			#events {
				height: rem(500);
				overflow-y: auto;
				padding-right: rem(10);
				position: relative;
				z-index: 2;

				&::-webkit-scrollbar-track {
					background-color: $color-black;
				}

				&::-webkit-scrollbar {
					background-color: $color-black;
					width: 8px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $color-black;
				}

				@include tablet-down {
					text-indent: text-indent(200);
				}

				> div {
					align-items: center;
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					font-size: rem(15);
					line-height: 1.2;
					line-height: 1;

					@include tablet-down {
						display: block;
						text-align: center;
					}

					.event-date {
						flex: 0 0 10%;
					}

					.event-venue {
						flex: 0 0 35%;
						padding: 0 rem(5);

						@include tablet-down {
							padding: rem(5) 0;
						}
					}

					.event-location {
						flex: 0 0 35%;
						padding-right: rem(5);

						@include tablet-down {
							padding-bottom: rem(5);
							padding-right: 0;
						}
					}

					.event-links {
						flex: 0 0 20%;
						text-align: right;

						@include tablet-down {
							text-align: center;
						}

						.btn {
							border: 1px solid $color-black;
							color: $color-black;
							display: inline-block;
							opacity: 1;
							padding: rem(10) rem(15);
							text-align: center;
							vertical-align: middle;

							@include hover-focus {
								opacity: 0.8;
							}
						}
					}

					+ div {
						margin-top: rem(20);
					}
				}
			}
		}

		> img {
			bottom: 0;
			height: 100%;
			object-fit: cover;
			object-position: center top;
			position: absolute;
			right: 0;
			top: 0;
			width: 40%;
			z-index: 1;

			@include desktop-sm-down {
				height: auto;
				position: static;
				width: 100%;
			}
		}
	}

	&#videos {
		background: $color-gray-2 url('../img/videos-bg.png') no-repeat center center;
		background-size: cover;
		padding: rem(80) 0 rem(220) 0;
		text-align: center;

		.owl-carousel {
			margin-bottom: rem(40);
			padding: 0 rem(50);

			@include tablet-down {
				padding: 0 rem(30);
			}

			.owl-video-wrapper {
				padding-bottom: 56.25%;

				iframe,
				.owl-video-tn {
					background-size: cover;
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
				}
			}

			.owl-nav {
				> button {
					font-size: rem(35);
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					&.owl-prev {
						left: 0;
					}

					&.owl-next {
						right: 0;
					}

					@include tablet-down {
						font-size: rem(20);
					}
				}
			}
		}

		.video-title {
			background: $color-black;
			color: $color-white;
			display: inline-block;
			font-size: rem(18);
			font-weight: bold;
			margin-top: rem(20);
			padding: rem(5) rem(10);
			vertical-align: middle;

			@include tablet-down {
				display: none;
			}
		}
	}

	&#instagram {
		padding: rem(80) 0 rem(20) 0;

		.section-title {
			font-size: rem(36);
			margin: 0 0 rem(80) 0;
		}

		#instagram-feed {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 rem(-12.5);

			> div {
				flex: 0 0 25%;
				margin-bottom: rem(25);
				padding: 0 rem(12.5);

				@include tablet-down {
					flex: 0 0 33.333333%;
				}

				@include phone-down {
					flex: 0 0 50%;
				}

				> a {
					display: block;
					opacity: 1;
					padding-bottom: 100%;
					position: relative;

					@include hover-focus {
						opacity: 0.8;
					}

					> img {
						bottom: 0;
						height: 100%;
						left: 0;
						object-fit: cover;
						object-position: center top;
						position: absolute;
						right: 0;
						top: 0;
						width: 100%;
					}
				}
			}
		}
	}

	@include tablet-down {
		padding: rem(60) 0 !important;
	}
}

footer {
	padding: rem(60) 0;

	.container {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		@include tablet-down {
			display: block;
			text-align: center;
		}

		.socials {
			flex: 0 0 50%;
			order: 2;
			text-align: right;

			@include tablet-down {
				margin-bottom: rem(30);
				text-align: center;
			}

			> ul {
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(30);
					line-height: 1;
					vertical-align: middle;

					+ li {
						margin-left: rem(20);
					}

					> a {
						color: $color-white;
						opacity: 1;
						@include hover-focus {
							opacity: 0.8;
						}
					}
				}
			}
		}

		.copyright {
			flex: 0 0 50%;
			font-size: rem(13);
			line-height: line-height(20, 13);
			order: 1;

			p {
				margin: 0;
			}
		}
	}
}

.popup {
	background: $color-gray-2;
	color: $color-white;
	margin: 20px auto;
	max-width: 500px;
	padding: rem(30);
	position: relative;
	width: auto;

	p {
		font-size: rem(17);
		line-height: line-height(24, 17);
		margin: 0;

		+ p {
			margin-top: rem(30);
		}
	}
}

.mfp-close {
	color: $color-white !important;
}

.fa-tiktok {
	background-color: transparent;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:cc='http://creativecommons.org/ns%23' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:sodipodi='http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd' xmlns:inkscape='http://www.inkscape.org/namespaces/inkscape' version='1.1' id='svg56' width='550.66669' height='570.66669' viewBox='0 0 550.66669 570.66669'%3e%3cdefs id='defs60' /%3e%3cpath style='fill:%23fff;stroke-width:1.33333337' d='M 182.66667,541.06282 C 132.41509,534.06041 94.747522,510.40998 67.029932,468.45769 49.686382,442.20715 40.961312,412.75268 40.950622,380.4181 c -0.009,-27.80075 5.37033,-50.57137 17.91847,-75.84598 25.22585,-50.81023 77.087088,-84.16134 134.464248,-86.47172 l 8.66667,-0.34898 0.35036,46.11966 0.35037,46.11965 -10.9996,2.16704 c -6.06383,1.19464 -15.27435,4.27974 -20.52609,6.87528 -18.7795,9.28134 -33.58338,30.33299 -36.74807,52.25707 -5.44534,37.72388 24.23828,74.49772 62.50335,77.43287 27.31485,2.0952 53.4764,-12.48484 65.14333,-36.30489 9.11666,-18.61324 8.76655,-10.40099 8.6257,-202.33334 L 270.57238,37.084747 h 46.54159 46.54161 l 0.91549,8.333334 c 5.42728,49.402224 37.94365,90.029209 84.09562,105.071729 7.92348,2.58253 13.26076,3.15933 35.33333,3.81849 l 26,0.77645 v 43.33334 43.33333 l -27.27852,0.36183 c -29.63979,0.39315 -40.30237,-0.88325 -60.55348,-7.24875 -13.85258,-4.35425 -35.16616,-14.37533 -47.38214,-22.27781 -5.24462,-3.39273 -9.81697,-6.1686 -10.16077,-6.1686 -0.3438,0 -0.62509,39.83643 -0.62509,88.5254 0,90.93218 -0.69034,107.24567 -5.38338,127.21725 -9.50459,40.44737 -42.13863,81.59496 -80.84332,101.93371 -10.93987,5.74873 -30.22466,12.41667 -43.5848,15.06993 -12.19548,2.42196 -40.32631,3.45851 -51.52185,1.89844 z' id='path3769' inkscape:connector-curvature='0' /%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: auto 100%;
	content: "";
	display: inline-block;
	height: .8em;
	left: 5%;
	position: relative;
	top: 3%;
	transition: background-image .3s;
	width: .8em;
}
