@font-face {
	font-family: 'Tungsten';
	src: url('../font/Tungsten-Semibold.woff2') format('woff2'),
	url('../font/Tungsten-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-tan: #f1e2cc;
$color-gray: #242d33;
$color-gray-2: #363e3e;